<template>
  <div class="bg-[#F7F7F7] min-h-screen">
    <div
      class="max-w-md mx-auto lg:pb-2 pb-32 md:max-w-6xl justify-center relative md:relative"
    >
      <span v-if="!path.includes('get-started')" class="text-sm block md:hidden px-3"
        >{{ $t("common.showing") }} {{ filtredProducts.length }}
        {{ $t("common.results") }}</span
      >
      <div v-if="path.includes('get-started')" class="px-3">
        <div class="flex flex-col items-center md:hidden">
          <h1 class="text-[27px] font-bold">
            {{ $t("testDetail.chooseYourTest") }}
          </h1>
          <p class="-mt-3">
            {{ displaySubTitle }}
          </p>
        </div>
      </div>
      <CommonPanelFilters :isPriceClear="isPriceClear" class="px-3"/>
      <div
        v-if="path.includes('get-started')"
        class="text-start flex-col items-start hidden md:flex px-3"
      >
        <h1 class="text-[27px] font-bold">
          {{ displayTitle }}
        </h1>
        <p class="-mt-3">
          {{ displaySubTitle }}
        </p>
      </div>
      <div
        v-if="!path.includes('get-started')"
        class="hidden md:flex flex-col mt-4 px-3"
      >
        <h1 class="text-[27px] font-bold text-left">
          {{ $t("testDetail.chooseYourTest") }}
        </h1>
        <p class="text-sm" v-if="availableProductCategoriesCount > 1">
          {{ displayFilterSubTitle }}
        </p>
      </div>

      <!-- selected filters  -->
      <div
        v-if="selectedFilters.length > 0 || productSearch"
        class="flex items-center px-3 gap-2 md:flex-wrap md:mt-5 mt-4 overflow-hidden w-full overflow-x-scroll md:overflow-auto"
      >
        <div
          v-for="filter in displayedFilters"
          v-show="!!filter.title"
          :key="filter"
          class="bg-secondary text-white max-w-md text-xs px-3 py-2 rounded-full flex items-center space-x-2 break-keep"
        >
          <span class="whitespace-nowrap">{{ filter.title }}</span>
          <button @click="clearSingleFilter(filter)">
            <XIcon class="w-4 h-4 cursor-pointer" />
          </button>
        </div>
        <div
          v-if="productSearch"
          class="bg-secondary text-white max-w-md text-xs px-3 py-2 rounded-full flex items-center space-x-2 break-keep"
        >
          <span class="whitespace-nowrap">{{ productSearch }}</span>
          <button @click="clearSearch()">
            <XIcon class="w-4 h-4 cursor-pointer" />
          </button>
        </div>
        <a
          @click="clearAllFilters"
          v-if="selectedFilters.length > 2"
          class="underline text-secondary text-xs cursor-pointer whitespace-nowrap"
        >
          {{ $t("filter.clearAll") }}
        </a>
      </div>

      <ClinicCheckoutPanel
        :startPoint="startPoint"
        v-if="!isTestsPage"
        :clinic="clinic"
        @closeModal="() => (openCheckoutPanel = false)"
        :openDrawer="openCheckoutPanel"
      >
      </ClinicCheckoutPanel>

      <div
        class="flex flex-wrap md:border-t border-gray-300 pt-6 mt-4 px-3"
        v-if="filtredProducts.length > 0"
      >
        <div
          v-for="product in paginationProducts"
          :key="product.id"
          class="product-card-modal flex basis-full md:basis-1/2 lg:basis-1/3 mb-8 transition-shadow duration-400 ease-out"
        >
          <ProductCard
            @showCheckoutPanel="addMultipleTests(true)"
            :item="product"
            :clinic="clinic"
            :startPoint="startPoint"
            :viewTestButtonText="$t('common.btnBookNow')"
            is-panel
            :serviceSlug="route.query.category"
          />
        </div>
      </div>
      <div v-else class="w-full h-[40vh] flex justify-center items-center px-3">
        <span>{{ $t("testPanel.noResult") }}</span>
      </div>
      <div
        v-if="
          filtredProducts.length > 12 && slicedIndex < filtredProducts.length
        "
        class="flex justify-center w-full py-10"
      >
        <button
          @click="showMore"
          class="base-outline-btn w-40 mx-auto font-semibold"
        >
          {{ $t("common.viewMore") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useProductStore } from "@/stores/filter";
import { XIcon } from "@heroicons/vue/outline";
import { useCheckoutStore } from "~/stores/checkout";
import { useMapStore } from "~~/stores/map";

const props = defineProps({
  showModal: Boolean,
  item: Object,
  hideChecked: Boolean,
  startPoint: {
    type: String,
    default: "get-started",
  },
});
const emit = defineEmits(["close", "openCheckPanel"]);

const globalStore = useGlobalStore();
const mapStore = useMapStore();
const filterStore = useProductStore();
const openCheckoutPanel = ref(false);
const { t } = useI18n();
const slicedIndex = ref(12);
const route = useRoute();
const path = route.path;

const availableProductCategoriesCount = computed(() => globalStore.globalData.categories.length);
const filterOptions = computed(() => filterStore.filterOptions);
const isTestsPage = ref(route.path.includes("tests"));
const tests = computed(() => mapStore?.panelTests);
const checkoutStore = useCheckoutStore();
filterStore.getFilterOptions(
  filterOptions.value.min_price,
  filterOptions.value.max_price
);

function addMultipleTests() {
  if (!isTestsPage.value) {
    openCheckoutPanel.value = true;
  } else {
    emit("openCheckPanel");
  }
}
const clinic = computed(() => mapStore.selectedClinic);

const isPriceClear = ref(false);

// FETCH FILTER OPTIONS

// onUnmounted(() => {
//  if((path.includes('clinics'))){
//    checkoutStore.removeTestLists()
//  }
// })

onMounted(() => {
  const pathname = trimLocaleFromPathname(path);

  if (!pathname.startsWith("/tests")) {
    checkoutStore.removeTestLists();
  }

  if (pathname.startsWith("/clinics") && availableProductCategoriesCount.value > 1) {
    filterStore.setCategory(filterOptions.value.categories.find((data) => data.slug == "sexual-health"))
    filterStore.setChecked("sexual-health");
  }
  if (pathname.startsWith("/get-started")) {
    const { service, section } = route.params || {};

    if (service) {
      filterStore.setService(service);
    }
    if (section) {
      filterStore.setCategory(section);
    }
  }
});
const min = computed(() => filterStore.min);
const max = computed(() => filterStore.max);

const selectedFilters = computed({
  get() {
    return filterStore.getCheckedFilter;
  },
});

const displayedFilters = computed(() => {
  return selectedFilters.value;
});
const productSearch = computed({
  get() {
    if (filterStore.search == "") {
      filterStore.setSortOption(2);
    } else {
      filterStore.setSortOption(0);
    }
    return filterStore.search;
  },
  set(val) {
    filterStore.setSearchValue(val);
  },
});

const categorySelected = computed(() => filterStore.category);

const serviceSelected = computed(() => filterStore.service);
const collectionSelected = computed(() => filterStore.collection);
const testTypesSelected = computed(() => filterStore.test_type);
const infectionSelected = computed(() => filterStore.infection);
const subCategorySelected = computed(() => filterStore.subcategory);
const checked = computed(() => filterStore.checked);
const sortOptionSelected = computed(() => filterStore.selectedSortOption);
const checkedTests = computed(() => checkoutStore.testLists);

const filtredProducts = computed(() => {
  let tempProducts = tests?.value.map((data) => ({
    ...data,
    price: Number(data.price),
  }));

  // Filter by sort option
  switch (filterStore.selectedSortOption) {
    // SORT BY LOW TO HIGH
    case 0:
      tempProducts = tempProducts?.sort((a, b) => a.price - b.price);
      break;

    // SORT BY HIGH TO LOW
    case 1:
      tempProducts = tempProducts?.sort((a, b) => b.price - a.price);
      break;

    // SORT BY ORDER BY RECOMMENDED
    case 2:
      tempProducts = tests?.value.map((item) => item);
      break;

    // SORT BY NAME-ASCENDING
    case 3:
      tempProducts = tempProducts?.sort((a, b) => (a.title > b.title ? 1 : -1));
      break;

    // SORT BY NAME-DESCENDING
    case 4:
      tempProducts = tempProducts?.sort((a, b) => (a.title < b.title ? 1 : -1));
      break;

    // DEFAULT SORT BY ORDER BY RECOMMENDED
    default:
      tempProducts = tests?.value.map((item) => item);
      break;
  }

  tempProducts = tempProducts.map((item) => item);
  // Filter by price Range
  tempProducts = tempProducts?.filter((item) => {
    return item.price >= min.value && item.price <= max.value;
  });
  // Filter by Search
  if (productSearch.value != "" && productSearch.value) {
    tempProducts = tempProducts?.filter((item) => {
      return (
        item.title.toUpperCase().includes(productSearch.value.toUpperCase()) ||
        (item.summary != null &&
          item.summary
            .toUpperCase()
            .includes(productSearch.value.toUpperCase())) ||
        (item.infections.length > 0 &&
          item.infections.some((infection) => {
            infection.title
              .toUpperCase()
              .includes(productSearch.value.toUpperCase());
          }))
      );
    });
  }
  // if ( route.path.includes("clinics") || route.path.includes("sexual-health")) {
  //   filterStore.setCategory(
  //     filterOptions.value.categories.find(
  //       (data) => data.slug == "sexual-health"
  //     )
  //   );
  //   filterStore.setChecked("sexual-health");
  // }
  // Filter By category

  if (categorySelected.value.length > 0 &&  route.path.includes("/get-started")) {
    tempProducts = tempProducts.filter((item) =>
      item.categories.some((category) =>
        categorySelected.value.includes(category.slug)
      )
    );
  }
  
  if (categorySelected.value.length > 0 && checked.value.length > 0 && !route.path.includes("/get-started")) {
    tempProducts = tempProducts.filter((item) =>
      item.categories.some((category) =>
        categorySelected.value.map((data) => data.slug).includes(category.slug)
      )
    );
  }

  // Filter By Sub Category
  if (subCategorySelected.value.length > 0 && checked.value.length > 0) {
    tempProducts = tempProducts.filter((item) =>
      item.categories.some((category) =>
        subCategorySelected.value
          .map((data) => data.slug)
          .includes(category.slug)
      )
    );
  }

  // Filter By service
  if (serviceSelected.value.length > 0) {
    tempProducts = tempProducts?.filter((item) => {
      return serviceSelected.value.includes(item.service_type.slug);
    });
  }
  //Filter by collection
  if (collectionSelected.value.length > 0 && checked.value.length > 0) {
    tempProducts = tempProducts?.filter((item) =>
      item.collection_methods.some((collection) =>
        checked.value.includes(collection.slug)
      )
    );
  }
  // Filter by types
  if (testTypesSelected.value.length > 0 && checked.value.length > 0) {
    tempProducts = tempProducts?.filter((item) => {
      return checked.value.includes(item.test_type.slug);
    });
  }

  // Filter by infection
  if (infectionSelected.value.length > 0 && checked.value.length > 0) {
    if (filterStore.infectionSortType == "AND") {
      tempProducts = tempProducts?.filter((item) =>
        Object.values(infectionSelected.value).every((infection) =>
          item.infections.map((data) => data.slug).includes(infection.slug)
        )
      );
    } else {
      tempProducts = tempProducts?.filter((item) =>
        item.infections.some((infection) =>
          Object.values(infectionSelected.value).includes(infection.slug)
        )
      );
    }
  }
  // const commonTests = tempProducts.filter(element => checkedTests.value.includes(element));
  // const uncommonTests = tempProducts.filter(element => !commonTests.includes(element));
  // // Sort Selected tests first
  // tempProducts = [...commonTests, ...uncommonTests];
  filterStore.setFiltredTests(tempProducts);

  return tempProducts;
});

const paginationProducts = computed(() => filtredProducts.value.slice(0, slicedIndex.value));

watch(() => tests, (newData) => {
if (newData.length > 0 && route.path.startsWith("/get-started")) {

  const getLists = newData
    .filter(item =>
      item.categories.some(category => route.params?.section.includes(category.slug)) &&
      route.params?.service.includes(item.service_type.slug)
    )
    .map(data => Number(data.price));
   filterStore.appendFilterOptions(Math.min(...getLists), Math.max(...getLists));
}
})


const displayTitle = computed(() => {
  return route.params.category === "everywhere-nurse-service"
    ? t("testPanel.chooseEverywhere", {
        test: filtredProducts.value.length > 1 ? "tests" : "test",
      })
    : t("testPanel.chooseClinicTest");
});

const displaySubTitle = computed(() => {
  return route.params.category == "everywhere-nurse-service"
    ? t("testPanel.everywhereTestAvailable", {
        total: filtredProducts.value.length,
        test: filtredProducts.value.length > 1 ? "tests" : "test",
      })
    : t("testPanel.totalTestsAvailable", {
        total: filtredProducts.value.length,
        test: filtredProducts.value.length > 1 ? "tests" : "test",
        section: t(`testPanel.getStartedSections.${route.params.section}`)
      });
});

const displayFilterSubTitle = computed(() => {
  return route.params.category == "everywhere-nurse-service"
    ? t("testPanel.everywhereTestAvailable", {
        total: filtredProducts.value.length,
        test: filtredProducts.value.length > 1 ? "tests" : "test",
      })
    : t("testPanel.resultText", {
        totalCount: tests.value.length,
        filteredCount: paginationProducts.value.length,
        test: filtredProducts.value.length > 1 ? "tests" : "test",
      });
});
function clearSearch() {
  filterStore.setSortOption(2);
  if (document.getElementById("search-product")) {
    document.getElementById("search-product").value = "";
  }
  filterStore.setSearchValue("");
}

const showMore = () => {
  slicedIndex.value += 12;
};

const clearSingleFilter = (item) => {
  filterStore.clearSingleItem(item);
};

const clearAllFilters = () => {
  clearSearch();
  const clearableFilters = props.hideChecked
    ? ["service", "infection", "collection", "checked", "price", "test_type"]
    : [
        "category",
        "service",
        "infection",
        "collection",
        "checked",
        "price",
        "test_type",
        "subcategory",
      ];
  filterStore.clearAllFilters(clearableFilters);
};

onBeforeUnmount(() => {
  clearAllFilters();
});

const closeModal = () => {
  emit("close");
};
</script>
